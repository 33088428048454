html {
    font-size: 12px;
}

html, body {
    height: 100%;
    color: #414168;
}

@media (min-width: 768px) {
    html {
        font-size: 14px;
    }
}

.modal-xxl {
    max-width: 1700px;
}

@media (min-width: 1200px) {
    .modal-lg {
        width: 1170px;
        min-width: 100%;
    }

    .modal-dialog {
        width: 1170px;
        min-width: 80%;
    }
}

.pointer {
    cursor: pointer;
}

.rowGrid {
    min-height: 33.5px;
}

.modal-header {
    background-color: black;
    color: #fff;
}

.close {
    color: #fff;
    opacity: 1;
}

    .close:hover {
        color: #fff;
        opacity: 1;
    }

    .close:visited {
        color: #fff;
        opacity: 1;
    }


.tab-pane {
    border-left: 1px solid #ddd;
    border-right: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    border-radius: 0px 0px 5px 5px;
    padding: 10px;
}

.customBreadCrumb .breadcrumb {
    background-color: transparent !important;
    padding-inline-start: 0;
    margin-block-start: 0;
    margin-block-end: 0;
    padding: 0;
    margin: 0;
}

.pagination > .active > a, .pagination > .active > a:hover, .page-item.active .page-link {
    background-color: #165C7D;
    border-color: #165C7D;
}

.pagination > li > a {
    color: #545b62
}

.card-header {
    padding: 0.4rem 1.25rem;
    font-size: 1rem;
    background-color: #fff;
}

.customnav-item {
    /*border-bottom: 2px solid transparent;
    */
}

    .customnav-item:hover, .customnav-item:active, .show {
        /* border-bottom: 2px solid #007bff;*/
    }

.nav-tabs .nav-link {
    margin-bottom: 0px;
    border-color: #dee2e6 #dee2e6 #dee2e6;
}

.btn-host {
    color: #ffffff;
    background-color: #165C7D;
    border-color: #165C7D;
}

.border-host {
    border-color: #165C7D;
}

.color-host {
    color: #165C7D;
}

.bg-host {
    background-color: #165C7D;
}

.bg-light-dark {
    background-color: #f0f0f0
}

.lb-sm {
    width: 60px;
}

.lb-md {
    width: 120px;
}


* {
    box-sizing: border-box;
}


.form-margim {
    margin: 1rem;
}

.form-control {
    min-height: 38px;
    height: calc(1.80rem + 2px);
    padding: .2rem .2rem;
}

.wrapper {
    display: grid;
    grid-template-columns: repeat(3, 2fr);
    grid-row-gap: 1em;
    grid-column-gap: 1em;
}

    .wrapper > div {
        padding: 4px;
        padding-bottom: 0;
        padding-top: 0;
    }

.border-left-primary {
    border-left: .25rem solid #165C7D !important;
}

.border-left-white {
    border-left: .25rem solid #ffffff !important;
}

.border-left-danger {
    border-left: .25rem solid #dc3545 !important;
}

.noborder {
    border: 0;
}

.cleanInput {
    border: 0;
    box-shadow: none;
    outline: 0 none;
    background: transparent;
}

.card-body-gridview {
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    padding: 0rem 0.9rem;
}

.card-header-gridview {
    padding: 0.1rem 1.0rem;
}

.card-header-form {
    padding: 0.3rem 1.25rem;
    border-bottom-color: #ffc107;
    border-bottom-width: 3px;
}

.container {
    max-height: 100%;
    max-width: 100%;
}

.modal.fade {
    z-index: 10000000 !important;
}

.col-height-xs {
    max-height: 35px;
}

.font_size_xxxs {
    font-size: 8px
}

.font_size_xxs {
    font-size: 14px
}

.max-with-14Col {
    max-width: 5.35%;
}

.max_navbar_height {
    max-height: 50px;
}


.input-group-sm > .form-control, .input-group-sm > .input-group-append > .btn, .input-group-sm > .input-group-append > .input-group-text, .input-group-sm > .input-group-prepend > .btn, .input-group-sm > .input-group-prepend > .input-group-text {
    height: calc(1.8125rem + 4px);
}

    .input-group-sm > .form-control:not(textarea), .input-group-sm > .custom-select {
        height: calc(1.5em + 0.5rem + 4px);
    }

.input-group-text {
    background-color: #fff;
    min-height: 38px;
}

.input-group-sm > .form-control, .input-group-sm > .custom-select, .input-group-sm > .input-group-prepend > .input-group-text, .input-group-sm > .input-group-append > .input-group-text, .input-group-sm > .input-group-prepend > .btn, .input-group-sm > .input-group-append > .btn {
    padding: 0.1rem 0.2rem;
}

/* skin.css Style*/
.upload-drop-zone {
    color: #ccc;
    border-style: dashed;
    border-color: #ccc;
    text-align: center
}

    .upload-drop-zone.drop {
        color: #222;
        border-color: #222;
    }

.badge-notify {
    background-color: #165C7D;
    position: relative;
    top: -13px;
    left: -24px;
}

.mailboxballon {
    white-space: pre-line;
    text-align: initial;
    word-break: break-word;
}

.overbglight:hover {
    background-color: #f8f9fa !important;
}

.mobileChangeProperty {
    transform: unset !important;
}