.timeline * {
    box-sizing: border-box;
}

.timeline {
    /*width: 95%;
    margin: 2em auto;*/
    position: relative;
    padding: 1em 0;
}

    .timeline::after {
        /* clearfix */
        content: '';
        display: table;
        clear: both;
    }

    .timeline::before {
        /* this is the vertical line */
        content: '';
        position: absolute;
        top: 0;
        left: 14px;
        height: 83%;
        width: 1px;
        background: grey;
    }

@media only screen and (min-width: 1170px) {
    .timeline.timeline--two-columns {
        margin-top: 3em;
        margin-bottom: 3em;
        width: 90%;
    }

        .timeline.timeline--two-columns:before {
            left: 50%;
            margin-left: -2px;
        }
}
