:root {
    --bgColor: #FAFCFF;
}


/* Provide sufficient contrast against white background */
body {
    margin: 0;
    text-align: left;
    font-family: 'Poppins', sans-serif;
    font: normal normal medium 12px/20px 'Poppins', sans-serif;
    letter-spacing: 0px;
    color: #07294C;
    opacity: 1;
    text-align: left;
    background-color: var(--bgColor);
}


.supressingToolTip .tooltip-inner {
    max-width: 600px;
}

.bg-menu {
    background-color: #313a46
}

a, .hrefStyle {
    color: #0366d6;
    cursor: pointer;
}

.infoIcon{
    color: #00000055;
    cursor: pointer;

}

.hrefStyle:hover {
    text-decoration: underline;
}

code {
    color: #E01A76;
}

.popover {
    max-width: 100%; /* Max Width of the popover (depending on the container!) */
}

.cursor-pointer {
    cursor: pointer;
}

.cursor-na {
    cursor: not-allowed;
}

.btn-primary {
    color: #fff;
    background-color: #1b6ec2;
    border-color: #1861ac;
}

.btn-host-invert {
    color: #0665ff;
    background-color: rgba(6, 101, 255, 0.2);
    border-color: #0665ff;
}

.loading-bullet {
    color: #1b6ec2;
}

.container {
    max-height: 100%;
    max-width: 100%;
}

.col-height-xs {
    max-height: 35px;
}

.font_size_xxxs {
    font-size: 8px
}

.font_size_xxs {
    font-size: 13px
}

.max-with-14Col {
    max-width: 5.35%;
}

.max_navbar_height {
    max-height: 50px;
}

.btn-host {
    color: #fff;
    background-color: #0665ff;
    border-color: #0665ff;
}

.hasAnalyticsHelp i {
    font-size: 0.7em;
    color: #6C757D;
}

.btn-host-button-group {
    color: #fff !important;
    background-color: #0665ff !important;
    border-color: #0665ff !important;
}

.btn-clever {
    color: #fff;
    background-color: #00c4b3;
    border-color: #00c4b3;
}

.btn-filter {
    border: 1px solid #5395ff;
    color: #5395ff;
    cursor: pointer;
    background-color: transparent;
}

.btn-custom-h:hover {
    color: white;
    background-color: #5a6268;
    border-color: #5a6268;
}

.btn-filter:hover {
    border: 1px solid #5395ff;
    background: #5395ff;
    color: white;
}

.badge-filter-count {
    border: 1px solid #5395ff;
    background: #5395ff;
    color: white;
}

.bg-host {
    background-color: #0665ff !important;
}

.bg-host-light {
    background-color: #d3eaff;
}

.bg-clever {
    background-color: #00c4b3;
}

.bg-purple {
    background-color: #7054E5;
}

.bg-purple-light {
    background-color: #f3f1fd;
}

.bg-yellow {
    background-color: #FBC753;
}

.bg-yellow-light {
    background-color: #fff8ea;
}

.bg-light-red {
    background-color: #F38375;
}

.bg-cyan {
    background-color: #4CC9F0;
}

.bg-cyan-light {
    background-color: #edf9fd;
}

.bg-violet {
    background-color: #CF53FA;
}

.bg-green {
    background-color: #6EDE8A;
}

.bg-green-light {
    background-color: #42D1A51A;
}

.bg-orange {
    background-color: #FF9827;
}

.bg-dark-orange {
    background-color: #E58823;
}

.bg-light-orange {
    background-color: #FFC17D;
}

.bg-red {
    background-color: #ff4444;
}

.bg-white {
    background-color: white;
}

.badge-host {
    background: #0665ff 0% 0% no-repeat padding-box;
    border-radius: 5px;
    color: #fff;
}
.badge-clever {
    background: #00c4b3 0% 0% no-repeat padding-box;
    border-radius: 5px;
    color: #fff;
}

.border-host {
    border-color: #0665ff !important;
}

.border-clever {
    border-color: #00c4b3;
}

.border-cyan {
    border-color: #4CC9F0 !important;
}

.border-light-red {
    border-color: #F38375 !important;
}

.border-yellow {
    border-color: #FBC753 !important;
}

.border-violet {
    border-color: #CF53FA !important;
}

.border-purple {
    border-color: #7054E5 !important;
}

.color-host {
    color: #0665ff;
}

.color-host-light {
    color: #d3eaff;
}

.color-purple {
    color: #7054E5;
}

.color-purple-light {
    color: #f3f1fd;
}

.color-yellow {
    color: #FBC753;
}

.color-yellow-light {
    color: #fff8ea;
}

.color-light-red {
    color: #F38375;
}

.color-cyan {
    color: #4CC9F0;
}

.color-violet {
    color: #CF53FA;
}

.color-green {
    color: #6EDE8A;
}

.color-dark-green {
    color: #4caf50;
}

.color-red {
    color: #ff4444;
}

.color-white {
    color: white;
}

.color-orange {
    color: #FF9827;
}

.color-dark-orange {
    color: #E58823;
}

.color-light-orange {
    color: #FFC17D;
}

.border-bottom-host {
    /* border-bottom: 1px solid #007bff !important;*/
}

.blur {
    filter: blur(4px);
}

.text-muted {
    color: #6F7388;
}

.title-sm {
    color: #6F7388;
    font-size: 0.8em;
    margin-bottom: 0.25rem;
}

.coolDatePicker .DateRangePickerInput__withBorder, .fullWidth .DateRangePickerInput__withBorder {
    display: flex !important;
    align-items: center !important;
}

.coolDatePicker .DateInput_input{
    box-shadow: none !important;
    padding: 0 1rem 0 0.5rem !important;
    font-size: 0.8em !important;
}

.coolDatePicker .DateRangePickerInput_calendarIcon.DateRangePickerInput_calendarIcon_1{
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    width: 7%;
    padding-right: 0;
}

.coolDatePicker .DateRangePickerInput_arrow_svg{
    height: 20px !important;
    width: 20px !important;
}

.coolDatePicker .DateRangePicker, .fullWidth .DateRangePicker {
    width: 100%;
}

.coolDatePicker .DateInput.DateInput_1, .fullWidth .DateInput.DateInput__small_2 {
    width: 40% !important;
}

.coolEdit:hover {
    color: #a3a3a3 !important;
    transition: 200ms;
}

.coolEditbkg:hover {
    background-color: #a3a3a3 !important;
    transition: 200ms;
}

.coolTextInput{
    box-shadow: none;
    padding: 0 !important;
}

.coolTextInput::placeholder {
    font-size: 0.8em;
    color: #808080;
    margin-left: 2px;
    margin-top: -100px;
    margin-right: 2px;
}

.coolDatePicker .DateRangePickerInput_arrow.DateRangePickerInput_arrow_1{
    width: 10%;
    display: flex;
    align-items: center;
    justify-content: center;
}

#CampaignTargets .css-1wa3eu0-placeholder{
    font-size: 0.8em;
}

.prettyEdit {
    position: absolute;
    right: 1.5rem;
    top: 1.5rem;
    cursor: pointer;
}

.prettyEdit:hover {
    color: #757575;
    transition: 200ms;
}

.without-arrows::-webkit-outer-spin-button,
.without-arrows::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Works for Firefox */
.without-arrows[type="number"] {
  -moz-appearance: textfield;
}

.text-ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.badgeCircle {
    border-radius: 50%;
    width: 36px;
    height: 36px;
    padding: 8px;
    background: #fff;
    border: 2px solid;
    color: #666;
    text-align: center;
}

.input-group-sm > .form-control, .input-group-sm > .input-group-append > .btn, .input-group-sm > .input-group-append > .input-group-text, .input-group-sm > .input-group-prepend > .btn, .input-group-sm > .input-group-prepend > .input-group-text {
    height: calc(1.8125rem + 4px);
}

    .input-group-sm > .form-control:not(textarea), .input-group-sm > .custom-select {
        height: calc(1.5em + 0.5rem + 4px);
    }

.input-group-text {
    background-color: #fff !important;
    min-height: 38px;
    border: 0;
    border-radius: 5px 0px 0px 5px;
}

.input-group-sm > .form-control, .input-group-sm > .custom-select, .input-group-sm > .input-group-prepend > .input-group-text, .input-group-sm > .input-group-append > .input-group-text, .input-group-sm > .input-group-prepend > .btn, .input-group-sm > .input-group-append > .btn {
    padding: 0.1rem 0.2rem;
}


input, textarea {
    padding-left: 1rem !important;
    border: 0 !important;
    box-shadow: 0px 3px 15px #6f73883b;
}

input[type="range"] {
    box-shadow: none !important;
}

input.clever[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    background-color: #00c4b3;
}

input.primaryRange[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    background-color: #1b6ec2;
}

.slider-track {
    width: 90%;
    height: 5px;
    position: absolute;
    margin: auto;
    top: 0;
    bottom: 0;
    border-radius: 5px;
}

.slider-track-input {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 90%;
    outline: none;
    position: absolute;
    margin: auto;
    top: 0;
    bottom: 0;
    background-color: transparent;
    pointer-events: none;
    padding-left: 0 !important;
    box-shadow: none !important;
}

.slider-track-input::-webkit-slider-runnable-track {
    -webkit-appearance: none;
    height: 2px;
}

.slider-track-input::-moz-range-track {
    -moz-appearance: none;
    height: 2px;
}

.slider-track-input::-ms-track {
    appearance: none;
    height: 2px;
}

.slider-track-input::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 1rem;
    width: 1rem;
    background-color: #3264fe;
    cursor: pointer;
    margin-top: -5px;
    pointer-events: auto;
    border-radius: 50%;
}

.slider-track-input::-moz-range-thumb {
    -webkit-appearance: none;
    height: 1rem;
    width: 1rem;
    cursor: pointer;
    border-radius: 50%;
    background-color: #3264fe;
    pointer-events: auto;
    border: none;
}

.slider-track-input::-ms-thumb {
    appearance: none;
    height: 1rem;
    width: 1rem;
    cursor: pointer;
    border-radius: 50%;
    background-color: #3264fe;
    pointer-events: auto;
}

.slider-track-input:active::-webkit-slider-thumb {
    background-color: #ffffff;
    border: 1px solid #3264fe;    
}

.hide-input-arrows {
    -moz-appearance: textfield;
}

.hide-input-arrows::-webkit-outer-spin-button, .hide-input-arrows::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

.page-item.active .page-link {
    background-color: #0665ff;
    border-color: #0665ff;
}

.tableResults .page-item.active .page-link, .tableResults .react-bs-table-csv-btn {
    background-color: #00c4b3 !important;
    border-color: #00c4b3 !important;
}

.cleanInput {
    border: 0;
    box-shadow: none;
    outline: 0 none;
    background: transparent;
}

.form-control {
    min-height: 38px;
    height: calc(1.80rem + 2px);
    padding: .2rem .2rem;
}

.hoverTextHost:hover {
    color: #0665ff !important;
}

.overbglight:hover, .table-hover tbody tr:hover td, .table-hover tbody tr:hover th {
    background-color: #ececec !important;
}

.overbglightBlue:hover {
    background-color: #d3eaff !important;
}

.scrollableDiv::-webkit-scrollbar {
    height: 10px;
    width: 10px;
}

.scrollableDiv::-webkit-scrollbar-track {
    background: rgb(241, 241, 241);
    border-radius: 10px;
}

.scrollableDiv::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: rgb(193, 193, 193);
}

.scrollableDiv {
    width: 100%; 
    height: 100%; 
    overflow-x: auto; 
    white-space: nowrap; 
    display: inline-block; 
    overflow-y: hidden;
}

.bothScrollableDiv::-webkit-scrollbar {
    height: 10px;
    width: 10px;
}

.bothScrollableDiv::-webkit-scrollbar-track {
    background: rgb(241, 241, 241);
    border-radius: 10px;
}

.bothScrollableDiv::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: rgb(193, 193, 193);
}

.bothScrollableDiv {
    width: 100%;
    height: 100%;
    overflow-x: auto;
    white-space: nowrap;
    display: inline-block;
    overflow-y: auto;
}

.chartLegend::-webkit-scrollbar {
    height: 1px;
    width: 4px;
}

.chartLegend::-webkit-scrollbar-track {
    background: rgb(241, 241, 241);
    border-radius: 10px;
}

.chartLegend::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: rgb(193, 193, 193);
}

.verticalScroll {
    overflow-y: auto;
}

.verticalScroll::-webkit-scrollbar-track {
    background: rgb(241, 241, 241);
    border-radius: 10px;
}

.verticalScroll::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: rgb(193, 193, 193);
}

.verticalScroll::-webkit-scrollbar {
    height: 1px;
    width: 4px;
}

.chartLegend {
    width: 100%;
    height: 93%;
    overflow: auto;
    white-space: nowrap;
    display: inline-block;
}

.profileBadge {
    height: 33px;
    width: 33px;
    border-radius: 40px;
    align-items: center;
    display: flex;
    justify-content: center;
    font-size: 12px;
    background-color: #007bff;
}

.toolTip {
    visibility: hidden;
    width: 170px;
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 8px 0;
    position: absolute;
    z-index: 1;
    top: 90%;
    font: normal normal medium 14px/21px Poppins;
    margin-left: -98px;
}

.toolTip::after {
    content: "";
    position: absolute;
    bottom: 100%;
    left: 50%;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent #555 transparent;
}

i .show {
    visibility: visible;
    animation: fadeIn 1s;
}

.bg-light {
    background: #FAFCFF 0% 0% no-repeat padding-box !important;
}

.bg-light-grey {
    background: #f8f9fa 0% 0% no-repeat padding-box !important;
}

.shadow {
    box-shadow: 0px 3px 15px #0000001f !important;
}

.modal-body {
    background: #FAFCFF 0% 0% no-repeat padding-box !important;
    border-radius: 10px;
    /*padding: 0 0 0 5px;*/
}

.modal-content {
    margin-top: 5%;
    border-radius: 10px;
}

.closeModal {
    color: grey;
    position: absolute;
    background-color: #FAFCFF;
    border-radius: 20px;
    padding: 4px 6px;
    width: 35px;
    height: 35px;
    top: -12px;
    right: -10px;
    font-size: 18px;
    z-index: 999;
    border: 0;
    /*box-shadow: 0px 3px 15px #0000009c !important;*/
}

.closeModal:hover {
    background-color: #6c757d;
    color: white;
}

.salesProcessModalButton button {
    right: -3px;
}

.salesProcessModalButton .card-body {
    padding: 5px;
}

.salesProcessMobileModal .modal-content {
    width: 96.6vw;
}

.text-host {
    color: #0665ff !important;
}

.text-host-light {
    color: #d3eaff !important;
}

.text-clever {
    color: #00c4b3 !important;
}

.tab-border-host {
    border-radius: 0px !important;
    border-bottom: 3px solid #0665ff !important;
}
.tab-border-clever {
    border-radius: 0px !important;
    border-bottom: 3px solid #00c4b3 !important;
}

.invisible-input input {
    padding: 0 !important;
    box-shadow: none !important;
    background: transparent;
}

.invisible-input input:focus {
    outline: none !important;
}


/* table*/
.react-bootstrap-table {
    background-color: white;
    box-shadow: 0px 3px 15px #6f73883b !important;
}

.TemplateVersionsTable .react-bootstrap-table{
    background-color: transparent !important;
    box-shadow: none !important;
}

.TemplateVersionModal .modal-body{
    background-color: white !important;
}

.noShadow .react-bootstrap-table {
    background-color: white;
    box-shadow: none !important;
}

.noShadow {
    box-shadow: none !important;
}

.redLineFormStyles{
    position: relative;
    width: calc(100% - 5px);
    height: 2px;
    background: red;
    transform: skewY(357.2deg);
    top: 20px;
    left: 3px;
    overflow: hidden;
}

.removeColor{
    position: absolute;
    bottom: 8.5px;
    right: 23px;
    color: #CCCCCC;
    border-radius: 10px;
    width: 22px;
    padding-left: 6.5px;
}

.removeColor:hover {
    cursor: pointer;
    color: #999999;
    border-radius: 10px;
    box-shadow: 0px 0px 5px 1px #00000040;
}

.tableHeader {
    border: 0 !important;
}

.table thead th {
    border-bottom: 2px solid #dee2e6 !important;
}
.table th, .table td {
    border: 0 !important;
}

.react-bootstrap-table .table-sm th, .react-bootstrap-table .table-sm td {
    padding: 0.5rem;
    vertical-align: middle !important;
    min-height: 40px !important;
}

.react-bootstrap-table-pagination {
    margin-top: 1rem !important;
}

.table-responsive::-webkit-scrollbar {
    height: 8px;
    width: 5px;
}

.table-responsive::-webkit-scrollbar-track {
    background: rgb(241, 241, 241);
    border-radius: 10px;
}

.table-responsive::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: rgb(193, 193, 193);
}

.table-responsive .table {
    margin-bottom: 0.5rem;
}


/*#region Custom Select*/
.css-yk16xz-control {
    border: 0 !important;
    padding-left: 5px !important;
    box-shadow: 0px 3px 15px #6f73883b !important;
}

.css-b8ldur-Input input, #react-select-3-input, .css-1g6gooi input {
    box-shadow: none !important;
}

.css-17b6lch-ValueContainer span, .css-10hlf6a span {
    flex-wrap: wrap;
    display: flex;
    overflow: auto;
    max-height: 2.3rem;
}

.css-g1d714-ValueContainer {
    max-height: 2.3rem;
    overflow: auto !important;
}

.css-g1d714-ValueContainer::-webkit-scrollbar, .css-10hlf6a span::-webkit-scrollbar, .css-17b6lch-ValueContainer span::-webkit-scrollbar {
    height: 4px !important;
    width: 4px !important;
}

.css-g1d714-ValueContainer::-webkit-scrollbar-track, .css-10hlf6a span::-webkit-scrollbar-track, .css-17b6lch-ValueContainer span::-webkit-scrollbar-track {
    background: rgb(241, 241, 241) !important;
    border-radius: 10px !important;
}

.css-g1d714-ValueContainer::-webkit-scrollbar-thumb, .css-10hlf6a span::-webkit-scrollbar-thumb, .css-17b6lch-ValueContainer span::-webkit-scrollbar-thumb {
    border-radius: 10px !important;
    background: rgb(193, 193, 193) !important;
}

.coolSelect .css-1uccc91-singleValue, .coolTextInput {
    font-size: 0.8em;
}

.big-combo .css-yk16xz-control, .big-combo .css-1pahdxg-control {
    min-height: 60px;
}

/*#endregion*/


/* DAte PIcker*/
.DateRangePickerInput, .DateRangePicker_1 {
    border: 0 !important;
    text-align: left;
    box-shadow: 0px 3px 15px #aeb2bb3b !important;
}

.SingleDatePickerInput {
    border: 0 !important;
    text-align: left;
    font: normal normal medium 14px/21px Poppins;
    width: 100%;
    box-shadow: 0px 3px 15px #6f73883b !important;
}

.SingleDatePicker {
    width : 100%;
}

.DateInput__small {
    min-width: 97px;
    /* width: calc(100% - 30px); */
}

.SingleDatePickerInput {
    display: flex;
    align-items: center;
    overflow: hidden;
}


/*Will edit selected date or the endpoints of a range of dates*/
.CalendarDay__selected, .CalendarDay__selected:active, .CalendarDay__selected:hover {
    background: #0665ff;
    border: 1px double #0665ff;
    color: white;
}

.CalendarDay__selected_span{ 
    background: #0665ffcc;
    border: 1px double #0665ffcc;
    color: #fff;
}

    .CalendarDay__selected_span:hover {
        background: #1861ac;
        border: 1px double #1861ac;
        color: #fff;
    }

.DateInput_input__focused {
    border-bottom: 2px solid #0665ff
}

.SingleDatePickerInput_calendarIcon_svg {
    fill: #495057
}

.DateRangePickerInput_calendarIcon {
    margin: 0px;
    height: 32px;
    padding: 6px;
    background-color: white;
}

.SingleDatePickerInput_calendarIcon {
    margin: 0px;
    height: 32px;
    padding: 6px;
    background-color: white;
}

.DateRangePickerInput_calendarIcon:disabled {
    background-color: #f2f2f2;
}

.SingleDatePickerInput_calendarIcon:disabled {
    background-color: #f2f2f2;
}

.SingleDatePickerInput__withBorder {
    border-radius: 3px;
    height: 38px;
}

.DateInput_input__small {
    height: 34px;
    /*width: 90px;*/
    box-shadow: 0px 0px 0px #6f73883b !important;
    padding-left: 4px !important;
    font-size: unset;
}


.DateRangePickerInput_calendarIcon_svg {
    fill: #495057
}

.DateRangePickerInput__withBorder {
    border-radius: 3px;
    height: 38px;
}

@-webkit-keyframes fadeIn {
    from { opacity: 0;}    
    to { opacity: 1;}
}

@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}


.week-day {
    color: #BEC1D0;
}

.has-events:hover {
    border: 1px solid #0665ff;
    border-radius: 10px;
}

.selected-day {
    background-color: #0665ff;
    border-radius: 10px;
    color: white;
}

.badge-not-selected {
    background-color: #cee1fe;
    color: #0665ff;
}

.has-events {
    background-color: #cee1fe;
    color: #0665ff;
    border-radius: 10px;
    cursor: pointer;
}

@media (max-width: 1515px) {
    .journey-calendar {
        flex: 0 0 32%;
        max-width: 32%;
    }
}


/*Campaign details */
.steps {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    overflow-x: auto;
    display: flex;
    justify-content: center;
}

.step:first-of-type .step-circle::before {
    display: none;
}

.step:last-of-type .step-content {
    padding-right: 0;
}

.step-content {
    box-sizing: content-box;
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 8rem;
    min-width: 8rem;
    max-width: 8rem;
    padding-top: 0.5rem;
    padding-right: 1rem;
}

.step-content-mobile {
    width: 4rem;
    min-width: 4rem;
}

.step-content-mobile-initiative {
    width: 3rem;
    min-width: 3rem;
}

.step-circle {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 1.5rem;
    height: 1.5rem;
    color: #0665ff;
    border: 2px solid #adb5bd;
    border-radius: 100%;
    background-color: #fff;
}

.step-circle::before {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    left: -2px;
    width: calc(8rem + 1rem - 1.5rem);
    height: 2px;
    transform: translate(-100%, -50%);
    color: #adb5bd;
    background-color: currentColor;
}

.step-circle-mobile::before {
    width: calc(4rem + 1rem - 1.5rem);
}

.step-circle-mobile-initiative::before {
    width: calc(3rem + 1rem - 1.5rem);
}

.step-text {
    color: #adb5bd;
    word-break: break-all;
    margin-top: 0.25em;
    margin-bottom: 0.1rem;
}

.step .step-circle {
    color: #fff;
    background-color: #0665ff;
    border-color: #0665ff;
}

    .step .step-circle::before {
        color: #0665ff;
    }

.step .step-text {
    color: #0665ff;
}

.step-error .step-circle {
    color: #fff;
    background-color: #0665ff;
    border-color: #0665ff;
}

    .step-error .step-circle::before {
        color: #0665ff;
    }

.step-error .step-text {
    color: #0665ff;
}

.step-active .step-circle {
    color: #0665ff;
    background-color: #fff;
    border-color: #0665ff;
}

    .step-active .step-circle::before {
        color: #0665ff;
    }

.step-active .step-text {
    color: #0665ff;
    font-weight: 500;
}

.bubble-chart-container .bubble-chart-text .bubble-label.medium{
    box-shadow: 1px 3px 15px 0px #ababab;
    z-index: 105;
}

.bubble-label.small{
    z-index: 100;
    font-size: 0.5em !important;
}

.bubble-chart-container .bubble-chart-text .bubble-label{
    z-index: 100;
}

.bubble-chart-text, .bubble-chart-d3 {
    top: 0 !important;
}

.bubble-chart-container{
    max-height: 100%;
}

.GeoChartHomePage {
    margin-top: -100px;
}

.homeGuestList {
    height: 900px;
    max-height: 900px;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 4% 6% 90%;
    grid-column-gap: 0px;
    grid-row-gap: 1.5%;
}

.homeChartsCol {
    height: 900px;
    max-height: 900px;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 88px 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0.3rem;
}

.CustomChart {
    margin-top: -130px;
}

.custom-file-label {
    border: unset;
    box-shadow: 0px 3px 15px #6f73883b !important;
    min-height: 38px;
    display: flex;
    align-items: center;
}
.custom-file-label::after{
    height:100% !important;
    display: flex !important;
    align-items: center;
}

.CloseButton:hover{
    background-color: #FFBDAD;
    color: #DE350B;
}

.table-responsive .table {
    margin-bottom: 0.5rem;
}

.table-responsive {
    margin-bottom: 1rem;
}

/* Communication Journey */
.bg-weekend {
    background-color: #f2f7ff;
}

.calendar-border {
    border: 1px solid #C9DEFE;
}

.bg-light-host-selected {
    background-color: #d3eaff !important;
}

.bg-host-selected {
    background-color: #0665ff;
    border-radius: 10px;
    color: white;
}

.bg-host-selected .badge {
    background-color: white;
    border-radius: 10px;
    color: #0665ff;
}

.bg-dark-blue {
    background-color: #07294c;
    color: white;
}

.bg-host-gradient {
    background: linear-gradient(90deg, rgba(75,199,240,1) 0%, rgba(10,105,250,1) 100%) !important;
    border: 0;
}

.bg-light-grey {
    background-color: #e2e6ed;
}

.text-dark-blue {
    color: #07294c;
}

.hoverTextBold:hover {
    font-weight: 600;
}

.hoverBgLightGrey:hover {
    background-color: #e2e6ed;
}

.styled-popover .popover {
    border: 0;
    box-shadow: 0px 3px 15px #6f73883b !important;
    border-radius: 10px;
}

.styled-popover .arrow {
    display: none;
}

.circle-md {
    width: 35px;
    padding: 4px 3px;
    text-align: center;
    border-radius: 20px;
}

.circle-sm {
    min-width: 25px;
    padding: 3px 3px;
    text-align: center;
    font-size: 12px;
    border-radius: 20px;
}

.circle-xs {
    min-width: 20px;
    padding: 3px 3px;
    text-align: center;
    font-size: 10px;
    border-radius: 20px;
}

/*Sales Automation*/
.pipeline {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    height: 70px;
}

.pipeline.smallPipeline{
    height: 20px;
}

.pipelineName {
    position: absolute;
    z-index: 2;
    width: 90%;
    overflow: hidden;
    padding: 0 15px 0 10px;
    text-align: center;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.pipelineName.smallPipeline {
    height: unset;    
}

.hover-underline:hover{
    text-decoration: underline;
}

.stagearrow, .arrow {
    width: 100%;
    height: 50%;
    background: #FFFFFF;
}

.stagearrow.small, .arrow.small {
    height: 9px;
}

.stagearrow.selectedStage, .arrow.selectedStage {
    background: #0665ff;
}

.stagearrow.top, .arrow.top {
    transform: skew(30deg, 0deg);
    /* border-left: 1px solid #0665ff;
    border-right: 1px solid #0665ff;
    border-top: 1px solid #0665ff; */
    height: 35px;
    box-shadow: 0px -5px 15px #8585851f;
}

.stagearrow.bottom, .arrow.bottom {
    transform: skew(-30deg, 0deg);
    /* border-left: 1px solid #0665ff;
    border-right: 1px solid #0665ff;
    border-bottom: 1px solid #0665ff; */
    box-shadow: 0px 18px 15px #79797914;
}

.stagearrow.top.smallPipeline, .arrow.top.smallPipeline {
    height: 10px;
}

.stagearrow.top.smallPipeline.small, .arrow.top.smallPipeline.small {
    height: 9px;
}

.stagearrow.bottom.noShadow, .arrow.bottom.noShadow {
    border-left: 1px solid lightgrey;
    border-right: 1px solid lightgrey;
    border-bottom: 1px solid lightgrey;
    box-shadow: none !important;
}

.stagearrow.top.noShadow, .arrow.top.noShadow {
    border-left: 1px solid lightgrey;
    border-right: 1px solid lightgrey;
    border-top: 1px solid lightgrey;
    box-shadow: none !important;
}

.stagearrow.bottom.noShadow.selectedStage, .arrow.bottom.noShadow.selectedStage {
    border: 1px solid #0665ff;
}

.stagearrow.top.noShadow.selectedStage, .arrow.top.noShadow.selectedStage {
    border: 1px solid #0665ff;
}

#RoibackSearchParameters .DateInput__small{
    width: unset !important;
}

#RoibackActive input, .LargeInput input{
    width: unset !important;
}

.SmallDatePicker .DateInput__small{
    width: 83px !important;
}

.NoPaddingInput{
    padding:0 !important;
}

.HijiffyMenuItems{
    cursor: pointer;
    width: 100%;
    height: 72px;
    align-items: center;
    display: flex;
    color: white;
    justify-content: center;
    font-size: 0.8em;
}

.InactiveCampaign{
    color: #e0e0e0 !important;
}

.btn-delete{
    background: #A80C19;
    border-color: transparent;
}

.btn-delete:hover{
    color: #fff;
    background-color: #5a6268;
    border-color: #545b62;
}

.stripoFolder {
    /*border: 1px solid #c9c9c9;*/
    border: 0;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-bottom: 0.8rem;
    margin-right: 1rem;
    vertical-align: middle;
    max-width: fit-content;
    border-radius: 5px;
    padding: 0.9rem 1.5rem;
    min-width: fit-content;
}

.stripoFolderTitle{
    white-space: nowrap;
    overflow: hidden;
    max-width: 250px;
    text-overflow: ellipsis;
}

.stripoUnsavedChanges{
    width: 10px;
    height: 10px;
    border-radius: 100px;
    background-color: #cbe5ff;
    position: absolute;
    margin-top: -8px;
    margin-right: -8px;
}

.formBuilderRemoveTab {
    width: 10px;
    height: 10px;
    border-radius: 100px;
    position: absolute;
    margin-top: -15px;
    margin-right: -8px;
    margin-left: -3px;
}

.formBuilderRemoveTa fa-times::before {
    color: white !important;
}

.smallModal .modal-content {
    max-width: 30vw;
}

#WhatsAppTemplates .custom-control{
    z-index: unset !important;
}

.fakeA{
    color: #0366d6;
    cursor: pointer;
}

.fakeA:hover{
    text-decoration: underline;
}

/*WhatsApp*/

.whatsAppBackground {
    overflow: auto;
    width: calc(100% - 2rem);
    position: absolute;
}

.emptyLang::before{
    content: '';
    background: #ababab;
    width: 18px;
    height: 14px;
    display: inherit;
}

.speechBubble {
    color: black;
    background: white;
    border-radius: 5px;
    position: relative;
    padding: 6px 7px 5px 9px;
    white-space: normal;
}


.right-point:before {
    content: "";
    width: 0px;
    height: 0px;
    position: absolute;
    border-left: 10px solid white;
    border-right: 10px solid transparent;
    border-top: 0px solid white;
    border-bottom: 10px solid transparent;
    right: -16px;
    top: 0px;
}

.left-point:before {
    content: "";
    width: 0px;
    height: 0px;
    position: absolute;
    border-left: 10px solid transparent;
    border-right: 10px solid white;
    border-top: 0px solid white;
    border-bottom: 10px solid transparent;
    left: -16px;
    top: 0px;
}

.msgInteraction {
    display: flex;
    visibility: hidden;
}

.speechBubbleRow:hover .msgInteraction {
    visibility: visible;
    align-items: center;
}

.replyTo {
    max-height: 55px;
    overflow: hidden;
    text-overflow: ellipsis;
    /*white-space: nowrap;*/
    /*white-space: break-spaces;*/
    font-size: 10px;
    border-left: #0665ff 3px solid;
    background-color: #bdbdbd38;
    border-radius: 4px;
}


/*STRIPO*/
#stripoSettingsContainer li {
    width: unset;
}

image-library-section .btn-group.pull-right{
    margin-top: unset !important;
    margin-right: unset !important;
}

image-library-section input.form-control.input-sm.upload-link{
    border-radius: var(--border-radius-base) !important;
}

image-library-section .input-group-btn.image-actions{
    padding-left: 15px;
}

image-library-section buttons-selector div.pull-right{
    margin-top: unset !important;
}

.sripo-watermark.center-block{
    display: none;
}

.notification-container {
    top: unset !important;
    bottom: 0 !important;
    margin-bottom: 15px;
}

.underlineHover:hover {
    text-decoration: underline;
    cursor: pointer;
}



.GoogleBusinessCard *::-webkit-scrollbar-track{
	border-radius: 10px;
	background-color: #F5F5F5;
}

.GoogleBusinessCard *::-webkit-scrollbar{
	width: 5px;
	background-color: #F5F5F5;
    height: 10px;
}

.GoogleBusinessCard *::-webkit-scrollbar-thumb{
	border-radius: 10px;
	background-color: #d9d9d9;
}

.block-ui-container{
    z-index: 99999;
}

.ellipsisText {
    overflow: hidden;
    text-overflow: ellipsis; 
    white-space: nowrap; 
}


.GPTbadge{
    transition: 500ms cubic-bezier(0.075, 0.82, 0.165, 1);
    width: 40px;
    height: 40px;
    border-radius: 500px;
    background: white;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: relative;
    z-index: 1;
}

.submitGPT {
    box-shadow: 0px 3px 15px #0000001f !important;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    border-radius: 500px;
    justify-content: center;
    cursor: pointer;
    background-color: white;
}

.GPTbadge:not(.activeBadge):hover, .activeBadge{
    background: #00A67E;
    transform: rotate(180deg);
}

.GPTbadge:not(.activeBadge):hover :first-child{
    transition: 500ms cubic-bezier(0.075, 0.82, 0.165, 1);
    display: none;
}

.GPTbadge:not(.activeBadge) :last-child{
    transition: 500ms cubic-bezier(0.075, 0.82, 0.165, 1);
    display: none;
}

.GPTbadge:not(.activeBadge):hover :last-child{
    display: unset;
}

.GPTbadge.activeBadge{
    background: #00A67E;
}

.GPTbadge.activeBadge :first-child{
    display: none;
}

.GPTmenu {
    background: white;
    padding: 0.7rem 0 20px;
    display: flex;
    align-items: center;
    flex-direction: column;
    border-radius: 10px;
    width: 30px;
}

.GPTmenu i:hover {
    transition: 200ms cubic-bezier(0.075, 0.82, 0.165, 1);
    color: rgb(0 166 126 / 40%);
}

.colorHover{
    transition: 200ms cubic-bezier(0.075, 0.82, 0.165, 1);
    color: #07294c;
    cursor: pointer;
}

.colorHover:hover {
    color: #6F7388;
}

.addPromptConfig .add {
    transition: 200ms cubic-bezier(0.075, 0.82, 0.165, 1);
    cursor: pointer;
    display: none;
}

.addPromptConfig:hover .add {
    display: block;
}

.generateGPT{
    border-radius: 500px;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: white;
    color: #00A67E;
}

.generateGPT:hover{
    color: #00a67f86;
}

input.gptRange[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    background-color: #00A67E;
}

#genericTextArea::-webkit-scrollbar {
    height: 1px;
    width: 4px;
}

#genericTextArea::-webkit-scrollbar-track {
    background: rgb(241, 241, 241);
    border-radius: 10px;
}

#genericTextArea::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: rgb(193, 193, 193);
}

.activeTemp{
    background-color: #00A67E;
    color: white;
    border: 1px solid white;
    cursor: pointer;
    padding: 0.75rem 1rem;
    border-radius: 5px;
    font-weight: 300;
}

.inactiveTemp{
    background-color: white;
    color: #00A67E;
    border: 1px solid #00A67E;
    cursor: pointer;
    padding: 0.75rem 1rem;
    border-radius: 5px;
    font-weight: 300;
}

.activeTemp.generate:hover, .activeTemp.generate:focus, .activeTemp.generate:active {
    background-color: #00A67E;
    color: white;
    border: 1px solid white;
}

.location-options {
    max-height: 300px;
    overflow: auto;
    display: flex;
    flex-direction: column;
    align-items: start;
}

.location-options::-webkit-scrollbar {
    height: 1px;
    width: 4px;
}

.location-options::-webkit-scrollbar-track {
    background: rgb(241, 241, 241);
    border-radius: 10px;
}

.location-options::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: rgb(193, 193, 193);
}

.genericHover:hover{
    text-decoration: underline;
}

.DateRangePicker_picker {
    z-index: 99;
}

.hoverShow:hover .hoverShowIcon {
    display: flex !important;
}

.hoverShow:hover {
    background: white;
}

.AddPhoneNumber, .hoverShow {
    transition: cubic-bezier(0.165, 0.84, 0.44, 1) 200ms;
}

.AddPhoneNumber:hover {
    background-color: #ffffff !important;
}

#GPTSelect .css-bp4ezv-singleValue {
    font-weight: 300;
    font-size: smaller;   
}

#GPTSelect input, #GPTSelect .css-180l4g5-control {
    box-shadow: unset;
}

.sendRun:hover{
    background-color: rgb(214, 214, 214);
}

.gptMenuEntry.active .blackLogo{
    display: none;
}

.gptMenuEntry .blueLogo{
    display: none;
}

.gptMenuEntry.active .blueLogo{
    display: block;
}

.noSize.modal-dialog {
    width: unset;
    min-width: unset;
}

.xxl.modal-dialog {
    width: 80vw;
    max-width: unset;
}

.metaAnalysisReport h1{
    font-size: 1.5em !important;
}

.metaAnalysisReport h2{
    font-size: 1.2em !important;
}

/* Very Cool ScrollBar */
.veryCoolScrollBar::-webkit-scrollbar {
    height: 7px;
    width: 7px;
}

.veryCoolScrollBar::-webkit-scrollbar-track {
    background: rgb(241, 241, 241);
    border-radius: 10px;
}

.veryCoolScrollBar::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: rgb(193, 193, 193);
}

/* Chunky Very Cool ScrollBar */
.chunkyVeryCoolScrollBar::-webkit-scrollbar {
    height: 8px;
    width: 4px;
    margin-top: 8px;
}

.chunkyVeryCoolScrollBar::-webkit-scrollbar-track {
    background: rgb(241, 241, 241);
    border-radius: 10px;
}

.chunkyVeryCoolScrollBar::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: rgb(193, 193, 193);
}


.formQuestion:focus{
    outline: #007bff 1px solid;
    border-radius: 3px;
}


/* Table with scroll */
.tableWithScroll {
    overflow-y: auto;
    /*box-shadow: 0px -3px 15px #6f73883b !important;*/
}

.tableWithScroll table {
    margin: 0;
}

.tableWithScroll thead {
    position: sticky;
    top: 0px;
    background-color: white;
    z-index: 99;
    border-bottom: 1px solid black;
}

.iMessage:last-child::before{
    content: "";
    position: absolute;
    z-index: 0;
    bottom: 0;
    left: -7px;
    height: 20px;
    width: 20px;
    background: #eee;
    border-bottom-right-radius: 15px;
}

.iMessage:last-child::after{
    content: "";
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: -10px;
    width: 10px;
    height: 20px;
    background: white;
    border-bottom-right-radius: 10px;
}

#react-select-5-input {
    box-shadow: none !important;
}

.coolBadge:hover {
    box-shadow: 0px 3px 15px #0000001f;
}

.coolBadge {
    cursor: pointer;
    font-size: 0.8em;
    padding: 0.4rem 0.7rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #b7b7b7;
    border-radius: 20px;
    margin-right: 0.5rem;
    color: #393939;
}

.coolBadge.selectedBadge {
    border: 1px solid #1b6ec2;
    color: #1b6ec2;
    background: #d7ebff;
    font-weight: 600;
    box-shadow: 0px 3px 15px #0000001f;
}

.coolBadge.selectedBadge:hover{
    box-shadow: none;
}

.cool-tab-border-host {
    border-radius: 0px !important;
    border-bottom: 1px solid #0665ff !important;
}

.coolBadge.smallBadge {
    padding: 0.2rem 0.5rem;
    margin-right: 0.25rem;
}

.coolBadge.squaredBadge {
    border-radius: 3px;
    padding: 0.2rem 0.3rem !important;
    margin: 0;
}

.coolTextInput:focus {
    border-color: unset !important;
    box-shadow: unset !important;
}

.coolInputGroup{
    border: 1px solid transparent;
    border-radius: 4px;
}

.css-1pahdxg-control {
    padding-left: 5px;
}

.coolInputGroup:focus-within {
    border: 1px solid #2684FF !important;
    box-shadow: 0 0 0 1px #2684FF !important;
}


/*#region monthly report*/
.floatingFilterCard {
    position: absolute;
    right: 0;
    margin-top: 30px;
    z-index: 999;
    padding-bottom: 40px
}

.floatingFilterCard .filterCard {
    background-color: white;
    min-width: 380px;
    min-height: 100px;
    border-radius: 10px;
    padding: 15px 20px 20px 20px;
}

.textEditor{
    box-shadow: 0px 3px 15px #6f73883b;
    border-radius: 10px;
}

.textEditorTabPane {
    border: transparent;
    background-color: white;
}



/*#endregion*/


/*#region sendGrid Activity*/

.vertical-line {
    border-left: 1px solid #ccc;
    height: 40px;
    margin-left: 10px;
    width: min-content;
}

.iconCircle{
    height: 14px;
    width: 14px;
    background-color: #007bff;
    border-radius: 100px;
    position: relative;
    display: inline-block;
    margin-left: 3px;
    margin-right: 5px;
    top: 3px;
}

.iconStyle{
    font-size: 8px;
    position: absolute;
    top: 3px;
    right: 5px;
    left: -1.8px;
}

/*#endregion*/

/*#region Profile Credit*/

.react-bootstrap-table .profileCreditDetailsTable {
    box-shadow: none !important;
    border: 1px solid #e2e6ed ;
}


/*#endregion*/

.pagination.react-bootstrap-table-page-btns-ul {
    justify-content: end;
}

/* For Removing Browsers Default Styles For Preview Email Templates */

#StripoPreview div, #StripoPreview span, #StripoPreview applet, #StripoPreview object, #StripoPreview iframe,
#StripoPreview h1, #StripoPreview h2, #StripoPreview h3, #StripoPreview h4, #StripoPreview h5, #StripoPreview h6, #StripoPreview p, #StripoPreview blockquote, #StripoPreview pre,
#StripoPreview a, #StripoPreview abbr, #StripoPreview acronym, #StripoPreview address, #StripoPreview big, #StripoPreview cite, #StripoPreview code,
#StripoPreview del, #StripoPreview dfn, #StripoPreview em, #StripoPreview img, #StripoPreview ins, #StripoPreview kbd, #StripoPreview q, #StripoPreview s, #StripoPreview samp,
#StripoPreview small, #StripoPreview strike, #StripoPreview strong, #StripoPreview sub, #StripoPreview sup, #StripoPreview tt, #StripoPreview var,
#StripoPreview b, #StripoPreview u, #StripoPreview i, #StripoPreview center,
#StripoPreview dl, #StripoPreview dt, #StripoPreview dd, #StripoPreview ol, #StripoPreview ul, #StripoPreview li,
#StripoPreview fieldset, #StripoPreview form, #StripoPreview label, #StripoPreview legend,
#StripoPreview table, #StripoPreview caption, #StripoPreview tbody, #StripoPreview tfoot, #StripoPreview thead, #StripoPreview tr, #StripoPreview th, #StripoPreview td,
#StripoPreview article, #StripoPreview aside, #StripoPreview canvas, #StripoPreview details, #StripoPreview embed, 
#StripoPreview figure, #StripoPreview figcaption, #StripoPreview footer, #StripoPreview header, #StripoPreview hgroup, 
#StripoPreview menu, #StripoPreview nav, #StripoPreview output, #StripoPreview ruby, #StripoPreview section, #StripoPreview summary,
#StripoPreview time, #StripoPreview mark, #StripoPreview audio, #StripoPreview video {
    margin: 0;
    padding: 0;
    border: 0;
    vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
#StripoPreview article, #StripoPreview aside, #StripoPreview details, #StripoPreview figcaption, #StripoPreview figure, 
#StripoPreview footer, #StripoPreview header, #StripoPreview hgroup, #StripoPreview menu, #StripoPreview nav, #StripoPreview section {
    display: block;
}

#StripoPreview ol, #StripoPreview ul {
    list-style: none;
}

#StripoPreview blockquote, #StripoPreview q {
    quotes: none;
}

#StripoPreview blockquote:before, #StripoPreview blockquote:after,
#StripoPreview q:before, #StripoPreview q:after {
    content: '';
    content: none;
}

#StripoPreview table {
    border-collapse: collapse;
    border-spacing: 0;
}

#stripoSettingsContainer .esdev-block-wrapper:has(div[esd-element-name="timerBlockHandler"]) {
    display: none;
}

#stripoSettingsContainer.stripoTimer .esdev-block-wrapper:has(div[esd-element-name="timerBlockHandler"]) {
    display: initial;
}

.context-menu {
    background: white;
    border: 1px solid #ccc;
    border-radius: 8px;
    position: absolute;
    z-index: 10;
}

.context-menu button {
    background: white;
    border: none;
    display: block;
    padding: 0.5em;
    text-align: left;
    width: 100%;
    border-top: 1px solid #ccc;
}

.context-menu button:last-child {
    border-radius: 0px 0px 8px 8px;
}

.context-menu button:hover {
    background: #ccc;
}

.layoutFlowBtn {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 10;
    background: white;
    color: black;
    border-radius: 5px;
    margin: 10px;
    padding: 0.5rem;
    box-shadow: 0px 3px 15px #6f73883b;
}

.layoutFlowBtn:hover {
    background: #ebebeb;
    color: black;
}

.configNodeStyleCondition{
    width: 40px !important;
    height: 40px !important;
    transform: rotate(45deg);
}

.cofigIconStyleCondition{
    transform: rotate(-45deg);
}

.edgebutton {
    width: 20px;
    height: 20px;
    background: #eee;
    border: 1px solid #fff;
    cursor: pointer;
    border-radius: 50%;
    font-size: 12px;
    line-height: 1;
}
  
.edgebutton:hover {
    box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.08);
}
  
.edgebutton-foreignobject body {
    background: transparent;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 40px;
}

.profileCustomerJourneyHover:hover{
    box-shadow: 0px 3px 15px #6f73883b;
}

#JourneyBuilderDatePicker .SingleDatePicker_picker {
    z-index: 999;
}

.hover-delete-condition:hover{
    color: #ff0015;
}

.draggable {
    cursor: move;
    cursor: drag;
    cursor: -moz-grab;
    cursor: -webkit-grab;
}

.draggable.active {
    cursor: move;
    cursor: dragging;
    cursor: -moz-grabbing;
    cursor: -webkit-grabbing;
}

.title-sm.required:first-child::after {
    content: '*';
    color: #dc3545;
    font-size: 0.9em;
    position: absolute;
}

.title-bold.required:first-child::after {
    content: '*';
    color: #dc3545;
    position: absolute;
}

.DayPicker_weekHeaders > .DayPicker_weekHeader.hideOdd  {
    visibility: hidden;
}

.DayPicker_weekHeaders > .DayPicker_weekHeader.hideEven  {
    visibility: hidden;
}


.cutomTooltip {
    position: relative;
    display: inline-block;
}

.cutomTooltip:hover .customTooltipBottomText {
    visibility: visible;
    opacity: 1;
}

.cutomTooltip .customTooltipBottomText {
    visibility: hidden;
    width: fit-content;
    background-color: #7b7b7b;
    color: #fff;
    text-align: center;
    border-radius: 3px;
    padding: 2px 10px;
    position: absolute;
    z-index: 999;
    top: 180%;
    left: 50%;
    margin-left: -60px;
    font-family: 'Poppins', sans-serif;
    font-size: 11px;
    white-space: nowrap;
}



.coolTooltip .tooltip-inner {
    background-color: #767679 !important;
    font-size: 0.9em !important;
    border-radius: 2px;
    color: white;
    font-weight: 600;
}

.coolTooltip.bs-tooltip-top .arrow, .coolTooltip.bs-tooltip-left .arrow, .coolTooltip.bs-tooltip-right .arrow, .coolTooltip.bs-tooltip-bottom .arrow {
    display: none;
}

.coolTooltip.bs-tooltip-auto .arrow {
    background: transparent;
}

.coolTooltip.bs-tooltip-right .arrow::before, .coolTooltip.bs-tooltip-auto[x-placement^="right"] .arrow::before {
    display: none;
}

.coolTooltip.bs-tooltip-left .arrow::before, .coolTooltip.bs-tooltip-auto[x-placement^="left"] .arrow::before {
    display: none;
}

.coolTooltip.bs-tooltip-bottom .arrow::before, .coolTooltip.bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
    display: none;
}

.coolTooltip.bs-tooltip-top .arrow::before, .coolTooltip.bs-tooltip-auto[x-placement^="top"] .arrow::before {
    display: none;
}

.coolTooltipWhite .tooltip-inner {
    background-color: #e3e3e6 !important;
    font-size: 0.8em !important;
    font-weight: 500;
    border-radius: 3px;
}

.coolTooltipWhite.bs-tooltip-top .arrow, .coolTooltipWhite.bs-tooltip-left .arrow, .coolTooltipWhite.bs-tooltip-right .arrow, .coolTooltipWhite.bs-tooltip-bottom .arrow {
    display: none;
}

.coolTooltipWhite.bs-tooltip-right .arrow::before, .coolTooltipWhite.bs-tooltip-auto[x-placement^="right"] .arrow::before {
    border-right-color: #e3e3e6 !important;
}

.coolTooltipWhite.bs-tooltip-top .arrow::before, .coolTooltipWhite.bs-tooltip-auto[x-placement^="top"] .arrow::before {
    border-top-color: #e3e3e6 !important;
}

.coolTooltipWhite.bs-tooltip-left .arrow::before, .coolTooltipWhite.bs-tooltip-auto[x-placement^="left"] .arrow::before {
    border-left-color: #e3e3e6 !important;
}

.coolTooltipWhite.bs-tooltip-bottom .arrow::before, .coolTooltipWhite.bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
    border-bottom-color: #e3e3e6 !important;
}

.coolPopover {
    box-shadow: 0px 3px 15px #0000001f !important;
    margin-left: 2rem !important;
    border: none;
    z-index: 1050;
}

.coolPopover .arrow{
    display: none;
}

.officeButton .office365badge:last-child {
    transition: 500ms cubic-bezier(0.075, 0.82, 0.165, 1);
}

.officeButton:hover .office365badge:last-child {
    transform: rotate(180deg);
}

.office365badge {
    height: 25px;
    width: 25px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    background: white;
    border-radius: 100%;
}

.statusStage {
    width: 100%;
    height: 5px;
    background-color: lightgray;
}

.statusStage.activeStatusStage {
    background-color: #0665ff;
}

.statusStage.activeStatusStage.statusFinished {
    background-color: #28a745;
}

.changeStatusOption:hover {
    box-shadow: 0px 3px 15px #0000001f !important;
    transition: 500ms cubic-bezier(0.075, 0.82, 0.165, 1);
}

.actionStatusIcon {
    transition: 500ms cubic-bezier(0.075, 0.82, 0.165, 1);
    width: 18px;
    height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    color: white;
    font-size: 0.9em;
}

.actionStatusIcon * {
    margin-left: 1px;
}

.actionStatusIcon:hover {
    box-shadow: 0px 3px 15px #0000001f !important;
}

.emailDescription {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    line-clamp: 3;
    -webkit-box-orient: vertical;
    max-height: 200px;
}

.emailDescription.smallDescription {
    max-height: 70px;
}

.disabledTab {
    pointer-events: none !important;
    cursor: not-allowed !important;
    color: #bababa !important;
}

.bigModal {
    width: 80vw !important;
    max-width: 80vw !important;
}

.calendar-border.isDealStartDate{
    background-color: #e7f1ff !important;
}

.calendar-border.isDuringDeal{
    background-color: #e7f1ff !important;
}

.calendar-border.isDealEndDate{
    background-color: #e7f1ff !important;
}

.sales-deal-calendar-rooms {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: 100%;
    padding: 0.25rem 0;
    background: #ffc107;
    color: white;
    border-radius: 20px;
}

.sales-deal-calendar-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0.25rem 0.5rem;
    color: white;
    border-radius: 20px;
}

.sales-deal-calendar-item *:not(i){
    font-size: 0.9em;
}


.sales-deal-calendar-item i{
    font-size: 0.8em;
}

.onGoingColorIcon {
    color: #0665ff;
}

.sales-deal-calendar-spaces {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: 100%;
    margin-top: 0.5rem;
    padding: 0.25rem 0;
    background: #28a745;
    color: white;
    border-radius: 20px;
}

.modal-medium-size{
    min-width: 60% !important;
}


.coolTextEditor .rdw-editor-wrapper{
    display: flex;
    flex-direction: column;
}

.coolTextEditor .rdw-editor-toolbar{
    border: 0;
    order: 2;
    margin: 0;
    padding: 0;
    border-top: 1px solid lightgrey;
    padding-top: 0.75rem;
    align-items: center;
}

.coolTextEditor .rdw-editor-main{
    order: 1;
    max-height: 60vh;
}

.coolTextEditor .rdw-editor-main::-webkit-scrollbar {
    height: 1px;
    width: 4px;
}

.coolTextEditor .rdw-editor-main::-webkit-scrollbar-track {
    background: rgb(241, 241, 241);
    border-radius: 10px;
}

.coolTextEditor .rdw-editor-main::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: rgb(193, 193, 193);
}

.coolTextEditor .rdw-editor-toolbar>div{
    border: 0;
    border-left: 1px solid lightgrey;
    margin: 0 !important;
    margin-left: 0.5rem !important;
    padding-left: 0.5rem !important;
    height: 30px;
}

.coolTextEditor .rdw-editor-toolbar *{
    box-shadow: none !important;
}


.coolTextEditor .rdw-editor-toolbar>div>div{
    border: 0;
}

.coolTextEditor .rdw-editor-toolbar > div:first-child{
    border-left: 0 !important;
    margin-left: 0rem !important;
    padding-left: 0rem !important;
}

.coolTextEditor .rdw-colorpicker-wrapper, .coolTextEditor .rdw-emoji-wrapper{
    width: 30px;
    align-items: center;
    justify-content: center;
}

.coolTextEditor .DraftEditor-root{
    min-height: 150px;
}

.coolTextEditor .public-DraftStyleDefault-block{
    margin-top: 0 !important;
}


.coolTextEditor:focus-within {
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.coolTextEditor{
    border-radius: 0.25rem;
    box-shadow: 0px 3px 15px #6f73883b;
    padding: 0.5rem;
    background-color: white;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.disabled-nav-item .nav-link {
    pointer-events: none;
    cursor: not-allowed;
    opacity: 0.5;
}

.SingleDatePicker_picker{
    z-index: 91;
}

.deal-action-status-Scheduled {
    color: #d3d3d3;
}

.deal-action-status-Cancelled {
    color: #F38375;
}

.deal-action-status-OnGoing {
    color: #0665ff;
}

.deal-action-status-Finished {
    color: #36ad51;
}

.deal-action-badge-Scheduled {
    border-radius: 4px;
    border: 1px solid #d3d3d3;
    color: #d3d3d3;
    cursor: pointer;
    transition: 200ms;
}
.deal-action-badge-ScheduledFixed {
    display: inline-block;
    border-radius: 4px;
    background-color: #d3d3d3;
    border: 1px solid #d3d3d3;
    color: white;
}

.deal-action-badge-Cancelled {
    border-radius: 4px;
    border: 1px solid #F38375;
    color: #F38375;
    cursor: pointer;
    transition: 200ms;
}

.deal-action-badge-CancelledFixed {
    display: inline-block;
    border-radius: 4px;
    background-color: #F38375;
    border: 1px solid #F38375;
    color: white;
}

.deal-action-badge-OnGoing {
    border-radius: 4px;
    border: 1px solid #0665ff;
    color: #0665ff;
    cursor: pointer;
    transition: 200ms;
}

.deal-action-badge-OnGoingFixed {
    display:inline-block;
    border-radius: 4px;
    background-color: #0665ff;
    border: 1px solid #0665ff;
    color: white;
}

.deal-action-badge-rooms {
    border-radius: 4px;
    border: 1px solid #ffc107;
    color: #ffc107;
    cursor: pointer;
    transition: 200ms;
}

.deal-action-badge-spaces {
    border-radius: 4px;
    border: 1px solid #28a745;
    color: #28a745;
    cursor: pointer;
    transition: 200ms;
}

.deal-action-badge-hotels {
    border-radius: 4px;
    border: 1px solid #d3d3d3;
    color: #d3d3d3;
    cursor: pointer;
    transition: 200ms;
}

.deal-action-badge-Effective {
    border-radius: 4px;
    border: 1px solid #6EDE8A;
    color: #6EDE8A;
    cursor: pointer;
    transition: 200ms;
}

.simulation-toggle {
    font-size: 0.8em;
    border-radius: 4px;
    border: 1px solid #0665ff;
    color: #0665ff;
    cursor: pointer;
    transition: 200ms;
}

.simulation-toggle:hover, .simulation-toggle:focus, .simulation-toggle.simulation-toggle-active {
    background-color: #0665ff;
    border-color: #0665ff;
    color: white;
}

.deal-action-badge-Finished {
    border-radius: 4px;
    border: 1px solid #36ad51;
    color: #36ad51;
    cursor: pointer;
    transition: 200ms;
}

.deal-action-badge-FinishedFixed {
    display: inline-block;
    border-radius: 4px;
    background-color: #36ad51;
    border: 1px solid #36ad51;
    color: white;
}

.deal-action-badge-EffectiveFixed {
    display: inline-block;
    border-radius: 4px;
    background-color: #6EDE8A;
    border: 1px solid #6EDE8A;
    color: black;
}

.walletHoverCol:hover .previewWalletCardHover {
    box-shadow: 0px 10px 20px 9px #0000004f;
}

.deal-action-badge-hotels:hover, .deal-action-badge-hotels:focus, .deal-action-badge-hotels.selected-deal-action-badge {
    background-color: #d3d3d3;
    border-color: #d3d3d3;
    color: white;
}

.deal-action-badge-Scheduled:hover, .deal-action-badge-Scheduled:focus, .deal-action-badge-Scheduled.selected-deal-action-badge {
    background-color: #d3d3d3;
    border-color: #d3d3d3;
    color: white;
}

.deal-action-badge-rooms:hover, .deal-action-badge-rooms:focus, .deal-action-badge-rooms.selected-deal-action-badge {
    background-color: #ffc107;
    border-color: #ffc107;
    color: white;
}

.deal-action-badge-spaces:hover, .deal-action-badge-spaces:focus, .deal-action-badge-spaces.selected-deal-action-badge {
    background-color: #28a745;
    border-color: #28a745;
    color: white;
}

.deal-action-badge-Cancelled:hover, .deal-action-badge-Cancelled:focus, .deal-action-badge-Cancelled.selected-deal-action-badge {
    background-color: #F38375;
    border-color: #F38375;
    color: white;
}

.deal-action-badge-OnGoing:hover, .deal-action-badge-OnGoing:focus, .deal-action-badge-OnGoing.selected-deal-action-badge {
    background-color: #0665ff;
    border-color: #0665ff;
    color: white;
}

.deal-action-badge-Finished:hover, .deal-action-badge-Finished:focus, .deal-action-badge-Finished.selected-deal-action-badge {
    background-color: #36ad51;
    border-color: #36ad51;
    color: white;
}


.deal-action-badge-Effective:hover, .deal-action-badge-Effective:focus, .deal-action-badge-Effective.selected-deal-action-badge {
    background-color: #6EDE8A;
    color: #07294C;
    border-color: #6EDE8A;
    font-weight: 700;
}

#SalesActionsPopOver p, #SalesProcess p {
    margin-bottom: 0;
}

.cityEvent-Note {
    border-radius: 4px;
    border: 1px solid #000a9c;
    background-color: #000a9c;
    color: white;
}

.cityEvent-Holiday {
    border-radius: 4px;
    border: 1px solid rgb(11, 128, 67);
    background-color: rgb(11, 128, 67);
    color: white;
}

.cityEvent-Event {
    border-radius: 4px;
    border: 1px solid rgb(3, 155, 229);
    background-color: rgb(3, 155, 229);
    color: white;
}

.noDisabledStyle {
    background-color: white !important;
}

    .noDisabledStyle .SingleDatePicker .SingleDatePickerInput__disabled, .noDisabledStyle .SingleDatePicker .SingleDatePickerInput__disabled .SingleDatePickerInput_calendarIcon:disabled,
    .noDisabledStyle .SingleDatePicker .SingleDatePickerInput__disabled .DateInput_input__disabled {
        background-color: white !important;
        font-style: normal;
    }

.DateRangePickerInput__showClearDates {
    padding-right: 0 !important;
}

.DateRangePickerInput__withBorder {
    display: flex;
    align-items: center;
    justify-content: start;
}

.previewWalletCard {
    width: 330px;
    box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
    border-radius: 15px;
}

.googleWalletLogo img{
    height: 30px;
    width: 30px;
    border: 1px solid white; 
    border-radius: 50px;
}

.googleWalletHero img{
    width: 100%;
    height: 110px;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
}

.googleWalletHeroConfig img{
    width: 100%;
    height: 44px;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
}

.walletBarCode > svg{
    width: 100%;
    height: 30px;
}

.drop-zone{
    border-style: dashed !important;
    min-height: 35px;
}

.editFieldBtn, .deleteFieldBtn {
    color: #6c757d;
    transition: color 0.3s ease;
}

.editFieldBtn:hover {
    color: #0665FF;
}

.deleteFieldBtn:hover {
    color: #FF0015;
}

.telPadding {
    padding-left: 52px !important;
}

.flag-container .selected-flag .arrow {
    width: 0 !important;
    height: 20% !important;
    background: none 0 !important;
}

.table-row-exec-report {
    transition: 250ms;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.table-row-exec-report:hover {
    background-color: rgb(250, 250, 250);
}

.CoolDataGrid {
    display: flex;
    overflow: auto;
}

.CoolDataGrid-header{
    white-space: nowrap;
}

.CoolDataGrid-cols {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    padding-block: 1rem;
    /* background-color: #f2f7f9; */
    background-color: #eff1f3;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 0.9em;
    color: #6F7388;
    font-weight: 500;
    border-right: 1px solid lightgrey;
}

.CoolDataGrid-cell {
    height: 45px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-left: 0.5rem;
    padding-right: 1.25rem;
    /* padding-block: 0.75rem; */
    border-top: 1px solid lightgrey;
    font-size: 0.9em;
    border-right: 1px solid lightgrey;
}

.CoolDataGrid-cell-small{
    height: 30px;
    padding-block: 0;
    display: flex;
    align-items: center;
    color: #6c757d;
}

.CoolDataGrid-rows-container {
    border-right: 1px solid lightgrey;
    background-color: white;
}
.CoolDataGrid-cols.CoolDataGrid-cols-weekend {
    background-color: #eaf0fb;
}

.CoolDataGrid-rows-container.CoolDataGrid-rows-container-weekend {
    background-color: #f7faff;
}

.CoolDataGrid-rows-container:last-of-type {
    border-right: unset;
}

.CoolDataGrid-cell:last-of-type {
    border-bottom: unset;
}

.CoolDataGrid-rows-container:hover {
    background-color: rgb(248, 248, 248);
}

.CoolDataGrid-cell:hover {
    background-color: rgb(236, 236, 236);
}

.cell-time-slots {
    width: 25%;
    cursor: pointer;
    border-radius: 3px;
    border: 1px solid lightgrey;
    background: lightgrey;
    transition: 200ms;
}

.cell-time-slots.cell-time-slots-empty {
    border: 1px solid transparent;
    background: transparent;
}

.CoolDataGrid-cell:hover .cell-time-slots-empty {
    border-color: #0665ff !important; 
}

.cell-time-slots-empty:hover {
    background-color: #0665ff;
}

.cell-time-slots-selected {
    background-color: #5f7383 !important;
    border-color: #5f7383 !important; 
}

.cell-time-slots-selected-focused {
    background-color: #0665ff !important;
    border-color: #0665ff !important; 
}

.row-space-list {
    transition: 200ms;
}

.row-space-list:hover {
    background-color: #f1f1f1;
}

.CoolDataGrid-cell:hover .cell-time-slots-empty.cell-time-slots-selected {
    border-color: #5f7383 !important; 
}

.CoolDataGrid-cellHover {
    background-color: rgb(248, 248, 248);
}

.thinScrollBar::-webkit-scrollbar {
    height: 5px;
    width: 5px;
}

.customNodeStyle {
    position: relative;
    max-width: 170px;
    min-height: 71px;
    max-height: 71px;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.25rem;
    font-weight: unset !important;
}

.customNodeStyle * {
    padding: 0px !important;
}

.CoolDataGrid-cell-separator {
    height: 20px;
    width: 100%;
    background: #d3d3d39c;
    font-size: 0.8em;
    font-weight: 600;
    padding: 0 0.5rem;
    display: flex;
    align-items: center;
    color: #07294cbd;
    border-top: 1px solid lightgrey;
}

.CoolDataGrid-cell-newHotel {
    border-top: 1px solid #777777;
}

.clickPlus {
    width: 37px;
    font-size: 1.5em;
    color: #cccccc;
    border-left: 1px solid lightgrey;
    cursor: default;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 200ms;
}

.mobileDateRangePicker > .DateRangePicker{
    width: 100%;
}

.CoolDataGrid-cols-today {
    padding: 0.3rem 0.4rem;
    border-radius: 5px;
    background: #546685;
    color: white;
}

.CoolDataGrid-cols-holiday {
    padding: 0.3rem 0.4rem;
    border-top: 2px dotted #000a9c;
}
.CoolDataGrid-cols-smallEvent {
    padding: 0.3rem 0.4rem;
    border-top: 2px dotted #369c00;
}
.CoolDataGrid-cols-event {
    padding: 0.3rem 0.4rem;
    border-top: 2px dotted #e8d41c;
}
.CoolDataGrid-cols-bigEvent {
    padding: 0.3rem 0.4rem;
    border-top: 2px dotted #ff3e15;
}

.clickPlus:hover {
    color: #999;
}

.original-rooms-input-create-block {
    background: transparent;
    height: unset;
    min-height: unset;
    box-shadow: none !important;
    padding-left: 0.2rem !important;
}

.btn-inventory {
    background-color: #fff;
    color: #0665ff;
    border-color: #0665ff;
}

.btn-inventory:disabled {
    color: #fff;
    background-color: #0665ff;
    border-color: #0665ff;
}

.btn-inventory:hover, .btn-inventory:active {
    background-color: #0665ff;
    color: #fff;
    border-color: #0665ff;
}

#MicroMassCalendar .CalendarDay__hovered_span.CalendarDay__default, #MicroMassCalendar .CalendarDay__hovered_span.CalendarDay__default:hover {
    background: #fff;
    border: 1px solid #e4e7e7;
    color: #484848;
}

#MicroMassCalendar .DayPickerKeyboardShortcuts_buttonReset{
    display: none;
}

#MicroMassCalendar .DateRangePicker_calendarIcon.DateRangePicker_calendarIcon_1,
#MicroMassCalendar .DateInput, #MicroMassCalendar .DateRangePickerInput_arrow.DateRangePickerInput_arrow_1,
#MicroMassCalendar .DateRangePicker_arrow.DateRangePickerInput_arrow_1{
    display: none !important;
}

#MicroMassCalendar .DateRangePicker {
    box-shadow: none !important;
}

#MicroMassCalendar .DateRangePicker_picker{
    z-index: 99 !important;
    top: 0 !important;
    width: 100% !important;
    position: relative !important;
}

#MicroMassCalendar .DayPicker__withBorder{
    box-shadow: unset !important;
}

#MicroMassCalendar .DateRangePickerInput__withBorder{
    border: 0 !important;
    height: 100%;
    box-shadow: none !important;
}

#MicroMassCalendar .CalendarDay__selected {
    background: #8bc3ff !important;
    color: white !important;
}

#MicroMassCalendar .CalendarDay__blocked_calendar, #MicroMassCalendar .CalendarDay__blocked_calendar:active, #MicroMassCalendar .CalendarDay__blocked_calendar:hover{
    background: #007bff !important;
    color: white !important;
}

#MicroMassCalendar .DayPickerNavigation_button__horizontalDefault{
    display: none;
}

#MicroMassCalendar.MobileCalendarBulk .DateRangePicker_picker {
    top: 52px !important;
}

#MicroMassCalendar.MobileCalendarBulk .DateRangePicker_picker{
    width: 100% !important;
    position: unset !important;
    top: unset !important;
    z-index: unset !important;
}

#MicroMassCalendar.MobileCalendarBulk .DateRangePickerInput__withBorder{
    height: unset !important;
}

#SalesProcessGrid .DateRangePickerInput, #SalesProcessGrid .DateRangePicker_1 {
    box-shadow: unset !important;
}

#SalesProcessGrid .DateInput_input {
    font-weight: 600;
}

#SalesProcessGrid .DateRangePickerInput_calendarIcon.DateRangePickerInput_calendarIcon_1 {
    display: none;
}

#SalesProcessGrid .DateInput.DateInput_1.DateInput__small.DateInput__small_2:first-of-type {
    margin-left: 0.75rem;
}

#SalesProcessGrid .DateInput.DateInput_1.DateInput__small.DateInput__small_2:last-of-type {
    margin-left: 0.5rem;
}

.smallerInput {
    min-height: 20px;
    height: 20px;
}

.colorInputWrapper {
    position: relative;
    overflow: hidden;
    width: 20px;
    height: 20px;
    border: solid 2px #ddd;
    border-radius: 40px;
}

.colorInputWrapper input[type="color"] {
    position: absolute;
    right: -8px;
    top: -8px;
    width: 56px;
    height: 56px;
    border: none;
}

.loader {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: inline-block;
    border-top: 3px solid lightgrey;
    border-right: 3px solid transparent;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
}
  
@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.cursor-default {
    cursor: default !important;
}

.addEvent:hover{
    background-color: #f1f1f1;
}

.rotate-icon {
    transition: transform 0.3s ease;
}

.rotate-up {
    transform: rotate(0deg);
}

.rotate-down {
    transform: rotate(180deg);
}

.cityEvent:hover {
    background-color: #f1f1f1;
}

#mobileDatePicker .SingleDatePicker_picker {
    left: unset !important;
    right: 0 !important;
}

.noScrollBar {
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.noScrollBar::-webkit-scrollbar { 
    display: none;
}

.tableElementHover:hover {
    background-color: #f1f1f1;
}

.salesProcessGridCellPopover {
    left: 200px !important;
}

.downloadProFormaModal {
    min-width: 85% !important;
    max-width: 90% !important;
}

.downloadProFormaModal > .modal-content {
    margin: 0;
}

.dowloadProFormaFields::-webkit-scrollbar {
    height: 0px;
    width: 7px;
}

.dowloadProFormaFields::-webkit-scrollbar-track {
    background: rgb(241, 241, 241);
    border-radius: 10px;
}

.dowloadProFormaFields::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: rgb(193, 193, 193);
}

/*#region SideBar*/
.sideBar {
    z-index: 99;
}

.sideBarExtension {
    width: 100px;
    min-width: max-content;
    max-height: 100%;
    position: relative;
    left: -5px;
    background-color: white;
    z-index: 9999;
    box-shadow: 15px 8px 15px 5px #00000014 !important;
    animation: openSideBar 1s ease 0s 1 normal forwards;
}

@keyframes openSideBar {
    0% {
        opacity: 0;
        transform: rotateY(-70deg);
        transform-origin: left;
    }

    100% {
        opacity: 1;
        transform: rotateY(0);
        transform-origin: left;
    }
}

.subMenusCard {
    position: absolute;
    left: 130%;
    background-color: white;
    border-radius: 2%;
    padding: 0.5rem 1rem;
    width: max-content;
    top: 0;
}

/*#endregion*/


.customTooltipBottomText {
    padding: 2px 10px !important;
    width: fit-content !important;
    text-align: left !important;
}

.resources-grid-focused-cell {
    box-shadow: inset 0px 0px 5px #0665ffad;
    border-color: #0665ff !important;
}

.vCardScanner section section {
    padding-top: 80% !important;
}

.vCardScanner.modal-dialog {
    margin-top: 20vh; 
}

.invalid-simulate {
    background-color: #ffdbdb !important;
    background: #ffdbdb !important;
}